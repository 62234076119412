import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/sections/Contact"

const ContactPage = () => {
  return (
    <Layout displayFooter="lg:hidden">
      <Seo title="Contacto" />
      <Contact />
    </Layout>
  )
}

export default ContactPage
