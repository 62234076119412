import React from "react"

const KayakButton = () => {
  return (
    <div className=" space-y-4">
      <a
        className="main-button mt-8"
        href="https://www.kayak.es/San-Francisco.48174.guide"
      >
        Guía de Viaje a San Francisco
      </a>
    </div>
  )
}

export default KayakButton
